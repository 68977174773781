@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

:root {
    --main-grey: #4d4d4d;
    --main-blue: #00BCE5;
    --main-white: #fff;
    --main-black: #000;
    --main-light-grey: #686868;
    --alert-green: #34c02f;
    --alert-red:rgb(247, 26, 26);

    --title-font: font-family: 'Inter', sans-serif;
}


html {
    font-size: calc(0.625rem + 0.41666666666666663vw);
    scroll-behavior: smooth;
}

@media screen and (max-width: 1440px) {
    html {
        font-size: calc(0.8126951092611863rem + 0.20811654526534862vw);
    }
}

@media screen and (max-width: 1920px) {
    html {
        font-size: calc(0.625rem + 0.41666666666666674vw);
    }
}

@media screen and (max-width:479px) { 
    html { 
        font-size: calc(0.7494769874476988rem + 0.8368200836820083vw); 
    } 
}



*{
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Sora', sans-serif;
    
}